import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';
import { NavLink } from 'react-router-dom';
import {
  MAIN_NAV_LINKS,
  MY_FLIGHTS,
  SEARCH_FLIGHTS,
} from '../../../utils/constants';
import Button from '../../Button/Button';
import { MainNavIconVariant } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { selectChatMessages } from '../../../redux/reducers/chatReducer';
import { getTotalUnreadMessagesCount } from '../../../utils/helpers';
import {
  selectCheckedInUsers,
  selectShouldFetchCheckedinFlights,
  setShouldFetchCheckedinFlights,
} from '../../../redux/reducers/checkinReducer';
import useCheckPermissions, {
  CheckType,
} from '../../../utils/hooks/useCheckPermissions';
import { Permission } from '../../../utils/generated/graphql';
import {
  IUserNotification,
  selectAllNotifications,
} from '../../../redux/reducers/newsfeedReducer';
import {
  OverlayModule,
  setOpenOverlay,
} from '../../../redux/reducers/overlayReducer';
import { setIsMenuOpen } from '../../../redux/reducers/headerReducer';
import { useGetMyFlights } from '../../../utils/hooks/useGetMyFlights';
import FeatureFlagUtil from '../../../utils/FeatureFlagUtil';
import { selectRoles } from '../../../redux/reducers/settingsReducer';

const navItemClassNames = 'list-none';
const linkClassNames = 'flex flex-col gap-1.5 items-center ';
const navbarClassNames =
  'w-64 h-full border-r-1 border-grey-12 dark:border-grey-80 flex flex-col justify-between';
const activeIconClassNames =
  'bg-secondary absolute top-12 h-2 w-2 rounded-full';

interface IMainNavLinkTablet {
  icon: MainNavIconVariant;
  link: string;
  isCheckedIn?: boolean;
}

const MainNavLinkTablet = ({ icon, link, isCheckedIn }: IMainNavLinkTablet) => {
  return (
    <li className={navItemClassNames}>
      <NavLink to={link} className={linkClassNames}>
        {({ isActive }) => {
          const iconClassNames = classNames({
            'fill-primary dark:fill-grey-12': !isActive,
            'fill-white dark:fill-grey-90': isActive,
          });
          const iconContainerClassNames = classNames(
            'w-[46px] h-44 flex justify-center items-center m-[9px] relative',
            {
              'bg-primary rounded-4 dark:bg-grey-12': isActive,
            }
          );
          return (
            <div className={iconContainerClassNames}>
              {link === '/favourites' && isCheckedIn && (
                <span
                  className={classNames(
                    activeIconClassNames,
                    'right-[5px] top-[5px]'
                  )}></span>
              )}
              <Icon
                width={18}
                height={18}
                variant={icon}
                className={iconClassNames}
              />
            </div>
          );
        }}
      </NavLink>
    </li>
  );
};

const MainNavTablet = () => {
  const dispatch = useDispatch();
  const { data, refetchMyFlights } = useGetMyFlights();
  const shouldFetchCheckedin = useSelector(selectShouldFetchCheckedinFlights);
  const isCheckedIn = !!data?.filter((flight) => flight.isCheckedIn)?.length;
  const chatMessages = useSelector(selectChatMessages);
  const allNotifications: IUserNotification[] = useSelector(
    selectAllNotifications
  );
  const roles = useSelector(selectRoles);

  const handleNotificationClick = () =>
    dispatch(setOpenOverlay(OverlayModule.NOTIFICATIONS));

  const hasNewNotifications = Object.values(allNotifications || [])
    .flat()
    .some((n) => !n.read);

  const handleChatClick = () => dispatch(setOpenOverlay(OverlayModule.CHAT));

  const handleTelephonyClick = () => {
    dispatch(setIsMenuOpen(false));
    dispatch(setOpenOverlay(OverlayModule.TELEPHONY));
  };
  const checkedInRoles = useSelector(selectCheckedInUsers);
  const hasNewMessages = !!getTotalUnreadMessagesCount(
    chatMessages,
    checkedInRoles
  );

  const isAdminPageEnabled = FeatureFlagUtil.showFeature(
    process?.env?.REACT_APP_TAC_ADMIN_PAGE_FEATURE_DISABLED ?? '',
    roles
  );

  const hasAdminAccess = useCheckPermissions(
    [Permission.READ_ALL_NOTIFICATIONS, Permission.READ_STATION_NOTIFICATIONS],
    CheckType.PARTIAL
  );

  const hasChatAccess = useCheckPermissions(
    [Permission.CHAT_READ, Permission.CHAT_WRITE],
    CheckType.PARTIAL
  );

  const hasTelephonyAccess = useCheckPermissions([Permission.TELEPHONY]);
  const hasMyFlightsAccess = useCheckPermissions([
    Permission.MANAGE_MY_FLIGHTS,
  ]);
  const hasSearchFlightsAccess = useCheckPermissions(
    [Permission.SEARCH_FLIGHTS, Permission.SEARCH_FLIGHTS_BY_CARRIER],
    CheckType.PARTIAL
  );
  const hasNotificationAccess = useCheckPermissions([
    Permission.DISPLAY_NOTIFICATIONS,
  ]);
  const noPermissionLinks: string[] = [];

  !hasSearchFlightsAccess && noPermissionLinks.push(SEARCH_FLIGHTS);
  !hasMyFlightsAccess && noPermissionLinks.push(MY_FLIGHTS);

  const filteredNavigationLinks = MAIN_NAV_LINKS.filter(
    (link) => !noPermissionLinks.includes(link.name)
  );

  useEffect(() => {
    if (shouldFetchCheckedin) {
      refetchMyFlights();
      dispatch(setShouldFetchCheckedinFlights(false));
    }
  }, [shouldFetchCheckedin]);

  return (
    <nav className={navbarClassNames}>
      <div>
        {filteredNavigationLinks.map((item) => (
          <MainNavLinkTablet
            key={item.icon}
            icon={item.icon}
            link={item.link}
            isCheckedIn={isCheckedIn}
          />
        ))}
      </div>
      <div>
        {isAdminPageEnabled && hasAdminAccess && (
          <NavLink to="admin" className={linkClassNames}>
            {({ isActive }) => {
              const iconClassNames = classNames({
                'fill-primary dark:fill-grey-12': !isActive,
                'fill-white dark:fill-grey-90': isActive,
              });
              const iconContainerClassNames = classNames(
                'w-[46px] h-44 flex justify-center items-center m-[9px]',
                {
                  'bg-primary rounded-4 dark:bg-grey-12': isActive,
                }
              );
              return (
                <div className={iconContainerClassNames}>
                  <Icon
                    width={32}
                    height={32}
                    variant="admin"
                    className={iconClassNames}
                  />
                </div>
              );
            }}
          </NavLink>
        )}
        {hasTelephonyAccess && (
          <Button
            onClick={handleTelephonyClick}
            className="flex flex-col w-64 h-[62px] justify-center items-center relative"
            Icon={
              <Icon
                variant="call"
                width={22}
                height={22}
                className=" dark:fill-grey-12 fill-primary"
              />
            }
          />
        )}
        {hasChatAccess &&
          FeatureFlagUtil.showFeature(
            process?.env?.REACT_APP_TAC_CHAT_FEATURE_DISABLED ?? '',
            roles
          ) && (
            <Button
              onClick={handleChatClick}
              className="flex flex-col w-64 h-[62px] justify-center items-center relative"
              Icon={
                <>
                  {hasNewMessages && (
                    <span
                      className={classNames(
                        activeIconClassNames,
                        'right-[15px]'
                      )}></span>
                  )}
                  <Icon
                    variant="chat"
                    width={24}
                    height={24}
                    className=" dark:fill-grey-12 fill-primary"
                  />
                </>
              }
            />
          )}
        {hasNotificationAccess && (
          <Button
            onClick={handleNotificationClick}
            className="flex flex-col w-64 h-[62px] justify-center items-center relative"
            Icon={
              <>
                {hasNewNotifications && (
                  <span
                    className={classNames(
                      activeIconClassNames,
                      'right-[15px]'
                    )}></span>
                )}
                <Icon
                  variant="mute"
                  width={19}
                  height={19}
                  className=" dark:fill-grey-12 fill-primary"
                />
              </>
            }
          />
        )}
      </div>
    </nav>
  );
};

export default MainNavTablet;
