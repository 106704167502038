import React, { ReactNode, MouseEvent, RefObject } from 'react';
import Button from '../Button/Button';
import PulseNotificationIcon from '../PulseNotificationIcon/PulseNotificationIcon';
import classNames from 'classnames';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';
const itemClassNames =
  'text-primary font-head-bold text-14 leading-[19px] flex justify-center items-center';

type BaseDropdownProps = {
  name: string;
};

interface ICheckinDropdownUI<T> {
  displayText: string | ReactNode;
  isDisabled?: boolean;
  handleDropdownClick: (
    e: MouseEvent<HTMLButtonElement>,
    displayText?: string | ReactNode
  ) => void;
  isOpen: boolean;
  clickOutsideRef: RefObject<HTMLButtonElement>;
  listItems: (T & BaseDropdownProps)[];
  handleItemSelection: (
    item: T & BaseDropdownProps,
    displayText?: string | ReactNode,
    e?: MouseEvent<HTMLButtonElement>
  ) => void;
  variant: string;
  className?: string;
  dropdownPosition?: string;
  showPulseNotification?: boolean;
  textClassName?: string;
  listTextClassName?: string;
  listClassName?: string;
}

const SingleSelectDropdown = <T,>({
  displayText,
  isDisabled = false,
  handleDropdownClick,
  isOpen,
  clickOutsideRef,
  listItems,
  handleItemSelection,
  variant,
  className,
  listClassName = 'hover:bg-grey-12 bg-white',
  dropdownPosition = 'below',
  showPulseNotification = false,
  textClassName = 'dark:!text-primary text-[0.8rem]',
  listTextClassName = 'dark:!text-primary',
}: ICheckinDropdownUI<T>) => {
  return (
    <>
      {FeatureFlagUtil.showFeature(
        process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
        []
      ) ? (
        <>
          <Button
            ref={clickOutsideRef}
            text={displayText}
            className={classNames(
              className,
              itemClassNames,
              'rounded-4 gap-[6px] h-44 flex-row-reverse p-8 justify-center',
              {
                '!bg-grey-12': isDisabled,
                'w-[147px] mobile:w-[139px]': variant === 'default',
                'w-full border-1 border-primary': variant === 'outlined',
                'w-[211px] text-white': variant === 'large',
              }
            )}
            onClick={(e) => handleDropdownClick(e, displayText)}
            Icon={showPulseNotification ? <PulseNotificationIcon /> : null}
            textClassName={classNames(textClassName, {
              'text-grey dark:!text-grey': isDisabled,
              'dark:text-white': variant === 'large',
              'dark:!text-primary':
                variant === 'outlined' || variant === 'default',
            })}
            disabled={!!isDisabled}
          />
          {isOpen ? (
            <div
              className={classNames(
                'absolute mt-4 rounded-4 border-1 border-grey-12 overflow-hidden !shadow-xl left-0 z-20',
                {
                  'bottom-full': dropdownPosition === 'above',
                  'top-full': dropdownPosition === 'below',
                }
              )}>
              {listItems?.map((item) => (
                <Button
                  className={classNames(
                    listClassName,
                    itemClassNames,
                    'h-40 border-b-1 border-b-grey-6 last:border-none px-[16px]',
                    {
                      'w-[145px]':
                        variant === 'default' || variant === 'outlined',
                      'w-[211px]': variant === 'large',
                    }
                  )}
                  text={item.name}
                  key={item.name}
                  onClick={(e) => handleItemSelection(item, displayText, e)}
                  textClassName={listTextClassName}
                />
              ))}
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default SingleSelectDropdown;
